import React from "react"
import Meta from "../utilities/seo"
import styled from "styled-components"

const Wrap = styled.div`
  padding: 5vw;
  color: var(--c-mint-100);
`

const Error = () => {
  return (
    <>
      <Meta
        title="404"
        image={{
          url: "",
          height: "",
          width: "",
        }}
        description=""
      />
      <Wrap>*Blog</Wrap>
    </>
  )
}

export default Error
